import { reportSentryWarning } from '@/utils/sentry';
import { setupStorage } from '@/utils/storage';
import { z } from 'zod';

//
// After OAuth /callback flow, redirect user to a success or error route
// Routes are stored in LocalStorage when initating OAuth sign-in flow
// and retrieved on /callback page
//

const redirectRoutesSchema = z
  .object({
    // Expect root-relative URLs (don't navigate to different domain)
    successUrl: z.string().startsWith('/'),
    errorUrl: z.string().startsWith('/'),
  })
  .nullable();

const redirectRoutesStore = setupStorage<z.infer<typeof redirectRoutesSchema>>(
  'after_oauth_redirect_routes',
  (val) => {
    const validationResult = redirectRoutesSchema.safeParse(val);
    return validationResult.success ? validationResult.data : null;
  }
);

//
// Expose to client
//

export const getRedirectRoutes = () => {
  return redirectRoutesStore.getItem();
};

export const setRedirectRoutes = (successUrl: string, errorUrl = successUrl) => {
  if (successUrl.startsWith('/callback') || errorUrl.startsWith('/callback')) {
    reportSentryWarning('Invalid after-OAuth redirect URL', { successUrl, errorUrl });
    // Fallback to taking user to homepage instead of /callback page, since the whole point of this
    // redirect is to take them from the OAuth /callback page to somewhere else.
    successUrl = successUrl.startsWith('/callback') ? '/' : successUrl;
    errorUrl = errorUrl.startsWith('/callback') ? '/' : errorUrl;
  }

  redirectRoutesStore.setItem({
    successUrl,
    errorUrl,
  });
};

export const clearRedirectRoutes = () => {
  redirectRoutesStore.setItem(null);
};
